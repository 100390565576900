
class InputTypeFile {
    constructor(elem) {
        this.elem = elem;
        this.modalContainer = document.getElementById('modal-upload-report');
        this.resultsList = this.modalContainer ? this.modalContainer.querySelector('.file-results-list') : '';
        this.template = document.querySelector('.template');
        this.dropZoneElement = document.querySelector(".drop-zone");
        this.init();
    }
    init(){
      const resultList = this.resultsList;
      const template = this.template;
      this.elem.addEventListener('change', function(e){
          if( this.files && this.files.length >= 1 ){
              document.querySelector('.file-results-list').innerHTML = '' ;
              for (let i =0; i < this.files.length; i++){
                  const firstClone = template.content.cloneNode(true);
                  const filenameNode = document.createTextNode(this.files[i]['name']);
                  firstClone.querySelector('.file-results-list__item .filename-container span').appendChild(filenameNode);
                  resultList.appendChild(firstClone);
              }
              const closeBtns = document.querySelectorAll('.file-results-list__item .action-container .close');
              closeBtns.forEach(btn => {
                  btn.addEventListener('click',function(e){
                  const removedItem = (this.parentNode.parentNode.querySelector('.filename-container span').innerHTML) ;
                  this.parentNode.parentNode.remove();
                  // const index = fileListArr.findIndex((file,index) => file.name === removedItem );
                  });
              });
          }
      })
        this.dropZoneElement.addEventListener('dragover', function(e){
            e.preventDefault();
        })
        this.dropZoneElement.addEventListener('drop', function(e){
            e.preventDefault();
            if (e.dataTransfer.files.length) {
                document.querySelector('.file-results-list').innerHTML = '' ;
                const input_files = document.querySelector('.drop-zone__input');
                input_files.files = e.dataTransfer.files;
                for (let i =0; i < input_files.files.length; i++){
                    const firstClone = template.content.cloneNode(true);
                    const filenameNode = document.createTextNode(input_files.files[i]['name']);
                    firstClone.querySelector('.file-results-list__item .filename-container span').appendChild(filenameNode);
                    resultList.appendChild(firstClone);
                }
                const closeBtns = document.querySelectorAll('.file-results-list__item .action-container .close');
                closeBtns.forEach(btn => {
                    btn.addEventListener('click',function(e){
                        const removedItem = (this.parentNode.parentNode.querySelector('.filename-container span').innerHTML) ;
                        this.parentNode.parentNode.remove();
                        // const index = fileListArr.findIndex((file,index) => file.name === removedItem );
                    });
                });
            }
        })
    }

    //     input.addEventListener( 'change', function( e )
    //     {
    //         var fileName = '';
    //         if( this.files && this.files.length > 1 )
    //             fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
    //         else
    //             fileName = e.target.value.split( '\' ).pop();
    //
    //         if( fileName )
    //             label.querySelector( 'span' ).innerHTML = fileName;
    //         else
    //             label.innerHTML = labelVal;
    //     });
    // });

}
export default InputTypeFile;
