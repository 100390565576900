class TopMenuAdmin{
    constructor(elem) {
        this.elem = elem;
        this.window = window;
        this.init();
    }
    init() {
        this.window.addEventListener('scroll',() => {
            if(this.elem){
                if(this.window.pageYOffset > 200){
                    this.elem.classList.add('fixed');
                }else {
                    this.elem.classList.remove('fixed');
                }
            }
    });
    }
}
export default TopMenuAdmin;
