class Dropdown {
    constructor(elem, target = undefined) {
        this.elem = elem;
        this.target = document.querySelector(this.elem.dataset.jsDropdownTarget);
        this.initialHeight = this.target.offsetHeight;
        this.init();
    }

    init() {
        this.elem.addEventListener("click", (e) => {
            e.preventDefault();
            this.target.classList.contains("expanded")
                ? this.collapseDropdown()
                : this.expandDropdown();
        });
    }
    collapseDropdown() {
        this.target.style.height = 0 + "px";
        this.target.classList.remove("expanded");
    }

    expandDropdown() {
        const targetHeight = this.target.scrollHeight;
        this.target.style.height = targetHeight + "px";
        this.target.classList.add("expanded");
    }
}
export default Dropdown;
