class TopMenuBurger {
    constructor(elem) {
        this.elem = elem;
        this.init();
    }
    init(){
        if(this.elem){
            this.elem.addEventListener('click',function (){
                const target = document.querySelector(this.dataset.jsTopMenuTarget);
                const toggle = document.querySelector(this.dataset.jsTopMenuToggle);
                this.classList.remove('show');
                toggle.classList.add('show');
                target.classList.add('show');
            })
        }
    }
}
export default TopMenuBurger;
