
import TopMenu from "./modules/top-menu";
import TopMenuAdmin from "./modules/top-menu-admin";
import Dropdown from "./modules/dropdown";
import Modal from "./modules/modal";
import InputFile from './modules/input-type-file';
import TopMenuDropDown from "./modules/top-menu-dropdown";
import TopMenuBurger from "./modules/top-menu-burger";
import TopMenuClose from "./modules/top-menu-close";
import MultiSelect from "./modules/multi-select";

const onload = () => {


  /** TOP MENU **/
  const topMenu = document.querySelector('.top-menu')
  if(topMenu){
    new TopMenu(topMenu);
  }
  /** TOP MENU ADMIN **/
  const topMenuAdmin = document.querySelector('.top-menu-admin');
  if(topMenuAdmin){
    new TopMenuAdmin(topMenuAdmin);
  }
  /** BURGER MENU  **/
  const burgerMenus = document.querySelectorAll(".burger");
  if (burgerMenus.length > 0 ){
    burgerMenus.forEach(burgerMenu => {
      new TopMenuBurger(burgerMenu);
    });
  }
  /** TOP MENU CLOSE **/
  const topMenuCloses = document.querySelectorAll("[data-js-top-menu-close]");
  if (topMenuCloses.length > 0 ){
    topMenuCloses.forEach(topMenuClose => {
      new TopMenuClose(topMenuClose);
    });
  }
  /** TOP MENU DROPDOWN **/
  const topMenuDropDowns = document.querySelectorAll("[data-js-top-menu-dropdown]");
  if (topMenuDropDowns.length > 0 ){
    topMenuDropDowns.forEach(topMenuDropDown => {
       new TopMenuDropDown(topMenuDropDown);
    })
  }
  /** DROPDOWN **/
  const dropdowns = document.querySelectorAll("[data-js-dropdown]");
  if (dropdowns.length > 0) {
    dropdowns.forEach((dropdown) => {
      new Dropdown(dropdown);
    });
  }
  /** MODAL **/
  const modals = document.querySelectorAll("[data-js-modal]");
  if (modals.length > 0) {
    modals.forEach((modal) => {
      new Modal(modal);
    });
  }
  /**** INPUT TYPE FILE ****/
  const inputs = document.querySelectorAll('.custom-file-input');
  if (inputs.length > 0) {
    inputs.forEach(input => {
      new InputFile(input);
    })
  }

  /** MULTISELECT **/
  const multiselects = document.querySelectorAll("[data-js-multi-select]");
  if (multiselects.length > 0) {
    multiselects.forEach((multiselect) => {
      new MultiSelect(multiselect);
    });
  }
}
window.onload = onload;
