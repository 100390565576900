class TopMenu {
    constructor(elem) {
      const __window = window;
      const topMenu = document.querySelector('.top-menu');
      /**-----------------**/
      /** STICKY SIDEBAR MAIN MENU**/
        const _sidebarWrapper = document.getElementById("sidebarWrap");
        const _sidebar = document.getElementById("sidebar-sticky");
        const _footer = document.getElementById("footer-sticky");

        let _footerTop = 0;
        let _sidebarWrapperTop = 0;
        let maxY = 0;
        if(_footer && _sidebarWrapper && _sidebar ){
            _footerTop = _footer.offsetTop;
            _sidebarWrapperTop = _sidebarWrapper.offsetTop;
            maxY = _footerTop - _sidebarWrapper.clientHeight;
            _sidebar.style.width=_sidebarWrapper.clientWidth+'px';
        }


        const sidebarHandler = function (y) {
            if(_sidebarWrapper && _sidebar){
                if (y > _sidebarWrapperTop) {
                    if ( y < maxY ) {
                        _sidebar.classList.add("fixed");
                        _sidebar.removeAttribute("style");
                        _sidebar.style.width=_sidebarWrapper.clientWidth+'px';
                    } else {
                        const topVar = maxY - _sidebarWrapperTop + "px";
                        _sidebar.classList.remove("fixed");
                        _sidebar.style.width=_sidebarWrapper.clientWidth+'px';
                        _sidebar.style.position = "absolute";
                        _sidebar.style.top = topVar;
                    }
                } else {
                    _sidebar.classList.remove("fixed");
                }
            }
        };

       /**-----------------**/
      /** WINDOW SCROLLING **/
        __window.addEventListener('scroll',function (){
            const y = __window.scrollY;
            if(topMenu){
                if( __window.pageYOffset > 200){
                    topMenu.classList.add('fixed');
                }else {
                    topMenu.classList.remove('fixed');
                }
            }
            sidebarHandler(y);
        });
    }
}
export default TopMenu;
