class Modal{
    constructor(elem) {
        this.elem = elem;
        this.target = document.querySelector(this.elem.dataset.jsModalTarget);
        if (this.target){
            this.closeBtn =  this.target.querySelector('.content-modal .--close');
        }
        if (this.elem && this.target){
          this.init();
        }
    }
    init(){
        
        this.elem.addEventListener("click", (e) => {
            e.preventDefault();
            this.target.classList.toggle('show');
        });
        this.closeBtn.addEventListener('click',(e) => {
            e.preventDefault();
            this.target.classList.remove('show');

        });
        window.addEventListener('click',(e) => {
            if(e.target === this.target){
                e.preventDefault();
                this.target.classList.toggle('show');
            }
        })
    }
}
export default Modal;
