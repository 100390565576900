class MultiSelect {
  constructor(elem) {
    this.elem = elem;
    this.checkboxes = null;
    this.select = null;
    this.buttonTarget = document.querySelector(this.elem.dataset.jsMultiSelectDropdownTarget);
    this.init();
  }

  init() {
    this.select = this.elem.querySelector("select[multiple='multiple']")
    this.checkboxes = this.elem.querySelectorAll("input[type='checkbox']");
    this.button = this.elem.querySelector("button");

    this.button.addEventListener("click", (e) => this.toggleDropdown(e));

    for(let i=0; i<this.checkboxes.length; i++) {
      if(this.select.options[i]) this.checkboxes[i].checked = this.select.options[i].selected;
      this.checkboxes[i].addEventListener("change", (e) => this.updateSelect(e, i));
    }
  }

  updateSelect(event, index) {
    this.select.options[index].selected = event.target.checked;
  }

  toggleDropdown(e) {
    e.preventDefault();
    if(this.buttonTarget.classList.contains("--open")) {
      this.elem.closest("form").submit();
    }
    this.buttonTarget.classList.toggle("--open");
  }
}

export default MultiSelect;
