function copie_nas(){
    temp_nas_input = document.getElementById('pers_nas_temp');
    temp_nas_input_value = temp_nas_input.value;

    if(!temp_nas_input_value.includes('#')){
        if(validateSIN(temp_nas_input_value)){
            temp_nas_input.setCustomValidity('');
            document.getElementById('pers_nas').value = temp_nas_input_value
            temp_nas_input.value = temp_nas_input_value.replace(/.(?=.{3})/g,'#')
        }else{
            temp_nas_input.setCustomValidity('NAS/SIN: incorrect');
            temp_nas_input.reportValidity();
        }
    }

}

function copie_ddn() {
    temp_ddn_input = document.getElementById('pers_datenaiss_temp');
    temp_ddn_input_value = temp_ddn_input.value;

    if (!temp_ddn_input_value.includes('#')) {
        if (validateDDN(temp_ddn_input_value)) {
            temp_ddn_input.setCustomValidity('');
            document.getElementById('pers_datenaiss').value = temp_ddn_input_value.replaceAll('/', '-')
            temp_ddn_input.value = temp_ddn_input_value.replace(/(\/|-)(\b\d{2})(\/|-)(\b\d{2})/, '-##-##')
        } else {
            if (temp_ddn_input_value.includes('#')) {
                return
            }
            temp_ddn_input.setCustomValidity('DDN: mauvais format ex:2022-01-01');
            temp_ddn_input.reportValidity();
        }
    }
}

function validateDDN(ddn) {
  return /^([0-9]{4}[-/]?((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))|([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00)[-/]?02[-/]?29)$/.test(ddn);
}

function validateSIN (sin) {
    let multiplyEveryOtherBy2 = (val, index) => val *((index%2)+1) ;
    let sumValues = (acc,val)=>acc+parseInt(val);

    if (typeof sin === 'number') {
        sin = sin.toString();
    }

    if (sin.length !== 9) {
        return false;
    }

    // convert to an array & pop off the check digit
    sin = sin.split('');
    const check = parseInt(sin.pop());
    const sum = sin
            .map(multiplyEveryOtherBy2)
            //To individual digits
            .join('').split('')
            .reduce(sumValues,0)
        * 9  % 10
    return check === sum;
}

function phone_focus_out(){
    document.getElementById('[phones][Domicile][phone]').reportValidity();
}

function checkboxes_clicked(){
    checkboxes = document.querySelectorAll('.checkboxes');
    if(document.querySelectorAll('.checkboxes:checked').length>0) {
        checkboxes.forEach(function(checkbox){
            checkbox.removeAttribute('required');
        })
    } else {
        checkboxes.forEach(function(checkbox){
            checkbox.setAttribute('required', 'required');
        })
    }
}

nas_input = document.getElementById('pers_nas_temp');
if(nas_input != null){
    nas_input.addEventListener( 'focusout', copie_nas);
}

ddn_input = document.getElementById('pers_datenaiss_temp')
if(ddn_input != null){
    ddn_input.addEventListener( 'focusout', copie_ddn);
}

phone_input = document.getElementById('[phones][Domicile][phone]')
if(phone_input != null) {
    phone_input.addEventListener('focusout', phone_focus_out);
}

checkboxes = document.querySelectorAll('div.new-employe-form form .checkboxes');
checkboxes.forEach(function(checkbox){
    checkbox.addEventListener('change', checkboxes_clicked);
});

function status_change() {
    const status = document.getElementById('pers_actif');
    const checkboxes = document.querySelectorAll('.checkboxes');
    if (!status.checked) {
        checkboxes.forEach(function (checkbox) {
            checkbox.removeAttribute('required');
            checkbox.removeEventListener('change', checkboxes_clicked);
        })
    } else {
        checkboxes.forEach(function (checkbox) {
            checkbox.setAttribute('required', 'required');
            checkbox.addEventListener('change', checkboxes_clicked);
        })
        checkboxes_clicked();
    }
}

if(document.getElementById('employe-form') != null){
    status_change();
    document.getElementById('pers_actif').addEventListener('change', status_change);
}

function input_changed() {
    window.onbeforeunload = function (event) {
        return "Vous avez des modifications non sauvegardé";
    }
}
function page_changed() {
    max_page = document.querySelector("div.pagination input#max_page").value;
    form = document.querySelector("div.search form");
    input = document.querySelector("div.search form input#page");
    new_page = this.value;
    input.value = new_page >= max_page ? max_page : new_page;
    form.submit();
}
function per_page_changed() {
    form = document.querySelector("div.search form");
    input = document.querySelector("div.search form input#per_page")
    input.value = this.value;
    page_input = document.querySelector("div.search form input#page")
    page_input.value = 1;
    form.submit();
}

function batch_delete(event, url){
    event.preventDefault();
    event.stopPropagation();

    checked = document.querySelectorAll('input[name="employe_id"]:checked');
    ids = Array.from(checked).map(c => c.value).join(', ');

    url = url + '&ids=' + ids
    window.location=url;
}

function check_all(){
    checkboxes = document.querySelectorAll('input[name="employe_id"]');
    checkboxes.forEach( ckeckbox => {
        console.log(ckeckbox);
        ckeckbox.checked = this.checked;
    });
}

inputs = document.querySelectorAll("div#employe div.edit-employe-form #employe-form input")
inputs.forEach( input => {
    input.addEventListener('change', input_changed);
});

document.querySelectorAll("select.per_page").forEach( function(input) {
    input.addEventListener('change', per_page_changed);
});

document.querySelectorAll("input#page_box").forEach( function(input) {
    input.addEventListener('change', page_changed);
});

document.querySelectorAll("a.delete").forEach( function(link) {
    link.addEventListener('click', (event) => batch_delete(event, link.href));
});

document.querySelectorAll("input#delete_all").forEach( function (input){
   input.addEventListener('click',  check_all);
});