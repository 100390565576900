class TopMenuDropDown{
    constructor(elem) {
      this.elem = elem;
      this.target = document.querySelector(this.elem.dataset.jsTopMenuDropdownTarget);
      this.init();
    }
    init(){
        if(this.elem){
          this.elem.addEventListener('click',(e) => {
              if(!this.target.classList.contains('show')){
                e.preventDefault();
              }
              this.target.classList.toggle('show');
          });
        }
    }
}
export default TopMenuDropDown;
