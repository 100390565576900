function input_changed() {
    window.onbeforeunload = function (event) {
        return "Vous avez des modifications non sauvegardé";
    }
}

inputs = document.querySelectorAll("div.company div.company-form #company-form input")
inputs.forEach( function(input){
    input.addEventListener('change', input_changed);
});

